$font__body: 'Noto Sans JP', '_YuGo', 'YuGothic', 'Yu Gothic', '_HiraKaku', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN W3', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro W3', 'Meiryo', 'メイリオ', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

$font__serif: 'Noto Serif JP', '_YuMin', 'YuMincho', 'Yu Mincho', '_HiraMin', 'Hiragino Mincho ProN', 'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN W3', 'Hiragino Mincho Pro', 'ヒラギノ明朝 Pro W3', 'Hiragino Mincho Pro W3', 'ＭＳ Ｐ明朝', 'MS PMincho', serif;
$font__monospace: 'Consolas', 'Courier New', monospace;

$font__size_base: 1rem;

$font__size_xs: .75rem;
$font__size_s: .875rem;
$font__size_ms: 1.125rem;
$font__size_m: 1.25rem;
$font__size_l: 1.5rem;
$font__size_xl: 2rem;
$font__size_xxl: 3rem;

$font__line-height_base: 1.5rem;

$font__line-height_xs: 1rem;
$font__line-height_s: 1rem;
$font__line-height_m: 1.5rem;
$font__line-height_l: 2rem;
$font__line-height_xl: 3rem;
$font__line-height_xxl: 4rem;
