.home {
  .banners {
    padding: 2rem 0 0;
  }

  .banner-list {
    margin: -1rem 0 0;
  }

  .banner {
    max-width: 335px;
    margin: 1rem auto 0;
  }

  .banner-link {
    display: block;
    overflow: hidden;
    height: 0;
    padding-top: 25%;
    background: center / cover no-repeat;
    text-indent: 100%;
    white-space: nowrap;
    transition: opacity .2s;

    @include link-selected {
      opacity: .7;
    }

    &--movie {
      background-image: url(images/banner_movie@mobile.jpg);
    }

    &--ryourinomachi {
      background-image: url(images/banner_ryouri@mobile.jpg);
    }

    &--ryourinomachi-2024 {
      background-image: url(images/banner_ryourinomachi-2024@mobile.jpg);
    }

    &--workation {
      background-image: url(images/banner_workation@mobile.jpg);
    }

    &--workation-movie {
      background-image: url(images/banner_workation_movie@mobile.jpg);
    }
  }
}

#intro {
  padding: 2rem 0;
  .inner {
    display: flex;
    justify-content: center;
  }
  .body-text {
    >p:not(:first-child) {
      margin-top: $size__margin * 2;
    }
    >p:last-child {
      margin-top: $size__margin * 3;
    }
    em {
      font-style: normal;
      font-weight: bold;
      >em {
        display: block;
        font-size: $font__size_m;
      }
    }
  }
}

@include media(desktop) {

  .home {
    .banner-list {
      display: flex;
      flex-flow: wrap;
      justify-content: center;
      margin: -1rem 0 0 -16px;

      .banner {
        flex: 0 0 auto;
        width: calc(100% / 3);
        max-width: 516px;
        margin: 1rem 0 0 0;
        padding: 0 0 0 16px;
        box-sizing: border-box;
      }

      &--columns {
        display: block;
        columns: 3;
        gap: 16px;
        margin-left: 0;

        .banner {
          width: auto;
          margin: 0;
          padding: 1rem 0 0;
        }
      }
    }

/*     .banner-link { */
/*       &--movie { */
/*         padding-top: 18.1%; */
/*         background-image: url(images/banner_movie@desktop.jpg); */
/*       } */

/*       &--workation { */
/*         padding-top: 18.1%; */
/*         background-image: url(images/banner_workation@desktop.jpg); */
/*       } */
/*     } */
  }

  #intro {
    .body-text {
      line-height: $font__size_base * 2.5;
      writing-mode: vertical-rl;
      >p:not(:first-child) {
        margin: 0;
        margin-block-start: 2.5rem;
        @include csshack(ms) {
          margin: 0 2.5rem 0 0;
        }
      }
    }
  }

}
