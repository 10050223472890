.section {
  margin-top: $size__margin * 2;
}

.section-header {
  margin: 0 0 $size__margin * 2;
}

.section-heading {
  display: flex;
  flex-flow: column;
  align-items: center;
  color: $color__main_deep;
  font-weight: normal;
  @include font-set(l);
  .text {
    &:first-child {
      padding: 0 $size__margin_side * 2 calc(#{$size__margin * .25} - 1px);
      border-bottom: 1px solid;
    }
    &.en {
      margin-top: $size__margin * .5;
      @include font-set(s);
      text-transform: uppercase;
    }
  }
}


