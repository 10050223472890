.index-header {
  background-image: url(images/header_bg.jpg);
  padding: $size__margin * 4 0 $size__margin * 2;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    width: 50%;
    margin: auto 0;
    background: url(images/header_bg_rectangles.svg) 0 center / auto 100% repeat-x;
  }
  &::before {
    right: 50%;
    margin-right: $size__base * 10;
    background-position: 100% center;
  }
  &::after {
    left: 50%;
    margin-left: $size__base * 10;
  }
  .tagline {
    margin-top: $size__margin * 2;
    @include font-set(l);
    letter-spacing: .25em;
    font-feature-settings: 'halt';
    >.text {
      display: block;
      margin-right: -.25em;
    }
  }
  .logo-yurihama-cho {
    position: absolute;
    top: $size__margin_side;
    left: $size__margin_side;
    img {
      height: $size__base * 2;
      margin: 0;
      padding: $size__margin_side * .5;
      border-radius: $size__margin_side * .25;
      background: rgba($color__header_logo-yurihama-cho, .7);
    }
  }
}

@include media(desktop) {

  .index-header {
    background-image: url(images/header_bg@2x.jpg);
    &::before {
      margin-right: $size__base * 12;
    }
    &::after {
      margin-left: $size__base * 12;
    }
    .heading {
      height: $size__base * 27;
      @include csshack(ie) {
        width: $size__base * 27 * $ratio__logo;
      }
    }
    .tagline {
      @include font-set(xl);
    }
    .logo-yurihama-cho {
      img {
        height: $size__base * 3;
      }
    }
  }

}
