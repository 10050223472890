a {
  color: $color__link;
  @include link-selected {
    color: $color__link-hover;
  }
}

img,
svg {
  max-width: 100%;
  vertical-align: top;
  @at-root .image & {
    width: 100%;
    height: auto;
  }
  @at-root .logo-image & {
    width: auto;
    height: 100%;
  }
}

@import "parts";
