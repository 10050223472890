.logo {
  height: $size__base * 20;
  @include csshack(ie) {
    width: $size__base * 20 * $ratio__logo;
  }
}

.logo-icon {
  width: $size__base * 2;
  height: $size__base * 2;
}

.logo-yurihama-cho {
  height: $size__base * 3;
}

.logo-image {
  a>img {
    transition: background-color $transition__button;
    @include link-selected {
      background-color: rgba(#fff, .9);
    }
  }
}

.buttons {
  margin-top: $size__margin;
  text-align: center;
}

@mixin button-color-reversed {
  border-color: currentColor;
  color: $color__button;
  background: $color__button_text;
  @include link-selected {
    border-color: $color__button-hover;
    color: $color__button_text;
    background: $color__button-hover;
  }
}

.button {
  display: inline-block;
  position: relative;
  margin: 0;
  padding: $size__margin * .75 $size__margin_side;
  border: 0;
  border-radius: 8px;
  color: $color__button_text;
  background: $color__button;
  @include font-set(base);
  font-feature-settings: 'halt';
  text-decoration: none;
  transition: background-color $transition__button;
  cursor: pointer;
  &.arrow {
    padding-right: $size__margin_side * 2;
    .icon {
      position: absolute;
      top: 0;
      right: $size__margin_side;
      bottom: 0;
      margin: auto 0;
      width: $size__base * .75;
      height: $size__base * .75;
      background: url(images/icons/arrow.svg) 0 0 / 100% auto no-repeat;
      transform: rotate(45deg);
    }
    &.back {
      padding-right: $size__margin_side;
      padding-left: $size__margin_side * 2;
      @include button-color-reversed;
      .icon {
        right: auto;
        left: $size__margin_side;
        transform: rotate(-135deg);
      }
    }
  }
  @include link-selected {
    color: $color__button_text;
    background: $color__button-hover;
  }
}
