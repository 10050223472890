@mixin csshack($browser) {
  @if $browser == ie {
    @at-root #{unquote('_:-ms-input-placeholder, :root')} & {
      @content;
    }
  }
  @if $browser == ms {
    @at-root #{unquote('_:-ms-lang(x),')} & {
      @content;
    }
  }
}
