@font-face {
  font-family: '_YuGo';
  src: local('Yu Gothic Medium');
}

@font-face {
  font-family: '_YuGo';
  src: local('Yu Gothic Light');
  font-weight: 100;
}

@font-face {
  font-family: '_YuGo';
  src: local('Yu Gothic Regular'),
       local('YuGothic-Regular');
  font-weight: 200;
}

@font-face {
  font-family: '_YuGo';
  src: local('Yu Gothic Bold');
  font-weight: 700;
}

@font-face {
  font-family: '_YuMin';
  src: local('Yu Mincho Regular');
}

@font-face {
  font-family: '_YuMin';
  src: local('Yu Mincho Light'),
       local('YuMincho-Light');
  font-weight: 200;
}

@font-face {
  font-family: '_YuMin';
  src: local('Yu Mincho Demibold'),
       local('YuMincho-Demibold');
  font-weight: 700;
}

@font-face {
  font-family: '_HiraMin';
  src: local('HiraMinProN-W3'),
       local('HiraMinPro-W3');
}

@font-face {
  font-family: '_HiraMin';
  src: local('HiraMinProN-W6'),
       local('HiraMinPro-W6');
  font-weight: bold;
}

@font-face {
  font-family: '_HiraKaku';
  src: local('HiraKakuProN-W3'),
       local('HiraKakuPro-W3');
}

@font-face {
  font-family: '_HiraKaku';
  src: local('HiraKakuProN-W6'),
       local('HiraKakuPro-W6');
  font-weight: bold;
}

body {
  color: $color__text;
  background: $color__background;
  font: $font__size_base#{'/'}$font__line-height_base $font__body
}

.serif {
  font-family: $font__serif;
}

.body-text {
  line-height: $font__line-height_l;
  >p {
    &:not(:first-child) {
      margin-top: $size__margin;
    }
  }
}

.article {
  .body-text {
    text-align: justify;
    >:not(:first-child) {
      margin-top: $size__margin;
    }
    h2 {
      @include font-set(l);
    }
    %padding {
      padding: $font__size_base / 4 0;
    }
    h3 {
      @extend %padding;
      @include font-set(m);
    }
    h4 {
      @extend %padding;
      @include font-set(ms);
    }
    %font-inherit {
      font-size: inherit;
      line-height: inherit;
    }
    h5 {
      @extend %font-inherit;
    }
    h6 {
      @extend %font-inherit;
      font-weight: normal;
    }
    %list-margin {
      margin-left: 2em;
    }
    ul {
      @extend %list-margin;
      list-style: disc;
    }
    ol {
      @extend %list-margin;
      list-style: decimal;
    }
    li {
      margin-top: $font__size_base / 2;
      line-height: $font__line-height_m;
    }
    >ul,
    >ol {
      li:last-child {
        padding-bottom: $font__size_base / 4;
      }
    }
    dd {
      @extend %list-margin;
    }
    blockquote {
      position: relative;
      z-index: 1;
      margin-right: $size__margin_side;
      margin-left: $size__margin_side * 3;
      &::before {
        content: '“';
        position: absolute;
        z-index: -1;
        top: -$font__size_base;
        left: -$size__margin_side * 2;
        font-weight: bold;
        font-size: $font__size_base * 10;
        line-height: 1;
        opacity: .05;
      }
      p>cite {
        display: flex;
        justify-content: flex-end;
        margin-right: -$size__margin_side;
      }
    }
    pre,
    code,
    kbd {
      font-family: $font__monospace;
    }
    pre {
      overflow-x: auto;
      overflow-y: hidden;
    }
    code,
    kbd {
      border: 1px solid $color__code_border;
      background: $color__code_background;
    }
    img {
      max-width: 100%;
      height: auto;
    }
    figcaption {
      margin: $size__margin * .5 0 $size__margin;
      font-size: $font__size_base * .8125;
      line-height: $font__size_base * 1.25;
      text-align: left;
    }
  }
}

.search-result {
  .search-heading {
    @include font-set(l);
  }
}

@include media(desktop) {

  .article {
    .body-text {
      >:not(:first-child) {
        margin-top: $size__margin * 2;
      }
    }
  }

}
