.header-locals {
  background: $color__background;
  &-content {
    padding: $size__base * 2 0;
  }
  .logo-locals {
    height: $size__base * 4;
    color: $color__main_deep;
    text-align: center;
    @include csshack(ie) {
      width: $size__base * 4 * $ratio__logo-locals-header;
    }
  }
  .logo-yurihama-cho {
    display: none;
  }
}

@include media(desktop) {

  .header-locals {
    &-content {
      padding: $size__base 0;
    }
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    background: rgba(#fff, .7);
    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .logo-yurihama-cho {
      display: block;
      height: $size__base * 2.5;
    }
  }

}
