@mixin media($res) {
  @if $res == only-mobile {
    @media screen and (max-width: #{$width__mobile - 1}) {
      @content;
    }
  }
  @if $res == tablet {
    @media screen and (min-width: $width__mobile) {
      @content;
    }
  }
  @if $res == tablet-large {
    @media screen and (min-width: $width__tablet) {
      @content;
    }
  }
  @if $res == desktop {
    @media screen and (min-width: $width__desktop) {
      @content;
    }
  }
}

@mixin font-set($size) {
  @if $size == xs {
    font-size: $font__size_xs;
    line-height: $font__line-height_xs;
  }
  @if $size == s {
    font-size: $font__size_s;
    line-height: $font__line-height_s;
  }
  @if $size == base {
    font-size: $font__size_base;
    line-height: $font__line-height_base;
  }
  @if $size == ms {
    font-size: $font__size_ms;
    line-height: $font__line-height_m;
  }
  @if $size == m {
    font-size: $font__size_m;
    line-height: $font__line-height_m;
  }
  @if $size == l {
    font-size: $font__size_l;
    line-height: $font__line-height_l;
  }
  @if $size == xl {
    font-size: $font__size_xl;
    line-height: $font__line-height_xl;
  }
  @if $size == xxl {
    font-size: $font__size_xxl;
    line-height: $font__line-height_xxl;
  }
}

@mixin clearfix {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

@mixin hide-text {
  overflow: hidden;
  white-space: nowrap;
  text-indent: 100%;
}

@mixin offscreen {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(100%);
}

@mixin link-selected {
  &:hover,
  &:active,
  &:focus {
    @content;
  }
}

@import "hacks";
