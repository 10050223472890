.body-text {
  .aligncenter {
    clear: both;
    margin-right: auto;
    margin-left: auto;
  }
  .wp-caption-text {
    margin: $size__margin / 2 0;
    @include font-set(s);
    text-align: left;
  }
  .wp-block-embed-youtube {
    position: relative;
    height: 0;
    padding-top: 56.25%;

    .wp-block-embed__wrapper {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;

      iframe {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@include media(tablet) {

  .body-text {
    @include clearfix;
    .alignleft {
      float: left;
      margin-right: $size__margin_side;
    }
    .alignright {
      float: right;
      margin-left: $size__margin_side;
    }
    .clear {
      clear: both;
    }
  }

}

