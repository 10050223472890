.article-header {
  margin: 0 (-$size__margin_side);
}

%wrap-text {
  >span {
    display: block;
  }
}

.article {
  margin: $size__margin 0;
}

.article-heading {
  padding: 0 $size__margin_side calc(1rem - 1px);
  border-bottom: 1px solid $color__main_deep;
  color: $color__main_deep;
  font-weight: normal;
  @include font-set(m);
  font-feature-settings: 'halt';
  @extend %wrap-text;
}

.article-header-meta {
  margin: $size__margin / 2 0;
  padding: 0 ($size__margin_side / 2);
  @extend %wrap-text;
  >span {
    margin: 0 ($size__margin_side / 2);
  }
}

.article-content {
  margin-top: $size__margin;
  .body-text {
    max-width: $width__max_body-text;
    margin: 0 auto;
  }
}

@include media(desktop) {

  .article {
    margin: $size__margin * 2 0;
  }

  .article-header {
    &-meta {
      display: flex;
      justify-content: center;
    }
  }

  .article-heading {
    @include font-set(l);
    text-align: center;
  }

  .article-content {
    margin-top: $size__margin * 2;
  }

}
