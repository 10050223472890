.search-content {
  padding: $size__margin * 2 0;
  background: $color__main_deep center / cover no-repeat fixed;
}

.section-locals {
  &-header {
    color: $color__text_search-header;
  }
  &-heading {
    height: $size__base * 6;
    text-align: center;
    filter: drop-shadow(0 0 8px rgba(#000, .2));
    @include csshack(ie) {
      width: $size__base * 6 * $ratio__logo-locals;
      margin: 0 auto;
    }
  }
  &-description {
    display: flex;
    justify-content: center;
    margin-top: $size__margin;
    text-shadow: 0 0 4px rgba(#000, .8);
    .body-text {
      h2,
      h3 {
        font-weight: inherit;
        font-size: inherit;
      }
    }
  }
}

.search-body {
  .box {
    margin: $size__margin (-$size__margin_side / 2);
    padding: $size__margin * 1.5 $size__margin_side * 1.5 $size__margin;
    border-radius: $size__base;
    overflow: hidden;
    background: rgba(#fff, .85);
  }
}

.search-query {
  &-item:not(:first-child),
  .value {
    margin-top: $size__margin;
  }
  .title>.label {
    display: flex;
    justify-content: flex-start;
    color: $color__text_input-label;
    @include font-set(ms);
    >.icon {
      display: block;
      flex: 0 0 auto;
      width: $font__line-height_m;
      height: $font__line-height_m;
      margin-right: $size__gutter / 2;
    }
  }
  .value-list {
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    max-width: 100%;
    margin-left: -$font__size_base;
    >li {
      flex: 0 0 auto;
      max-width: 100%;
      margin-left: $font__size_base;
    }
    .label {
      display: flex;
      align-items: baseline;
      >input {
        margin-right: $font__size_base / 2;
      }
    }
  }
  .input-text {
    width: 100%;
    padding: 0 calc(#{$size__margin_side / 4} - 1px);
    height: $font__size_base * 2;
    box-sizing: border-box;
  }
}

.search-locals-gallery {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  margin: calc(#{-$size__margin} - #{$size__gutter / 2}) (-$size__margin_side) 0;
  .image {
    flex: 0 0 auto;
    width: calc((100% - #{$size__gutter}) / 3);
    margin-top: $size__gutter / 2;
  }
  a {
    transition: opacity $transition__button;
    @include link-selected {
      opacity: $transition__prop_opacity;
    }
  }
}

.search-result {
  .search-heading {
    color: $color__text_input-label;
    text-align: center;
  }
  .search-query {
    &-item {
      margin-top: $size__margin;
    }
    .value {
      margin-top: $size__margin / 2;
      &.empty {
        color: $color__text_disabled;
      }
    }
    .keyword {
      %keyword-quote-styles {
        color: $color__text_input-label;
        font-weight: bold;
      }
      dd:not(.empty) {
        &::before {
          content: '“';
          margin-right: .25em;
          @extend %keyword-quote-styles;
        }
        &::after {
          content: '”';
          margin-left: .25em;
          @extend %keyword-quote-styles;
        }
      }
    }
    .value-list {
      @include csshack(ie) {
        display: block;
        >li {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
  .result {
    margin: $size__margin * 2 0;
    >em {
      font-style: normal;
      font-weight: bold;
    }
  }
  &-list {
    margin: $size__margin * 2 #{-$size__margin_side};
    li {
      margin-top: $size__margin / 2;
    }
    a {
      display: flex;
      align-items: flex-start;
      color: inherit;
      text-decoration: none;
      @include link-selected {
        text-decoration: underline;
        .image {
          background: $color__background_image-hover;
          >img {
            opacity: $transition__prop_opacity;
            transition: opacity $transition__button;
          }
        }
      }
    }
    .image {
      flex: 0 0 auto;
      width: $size__base * 4;
      height: $size__base * 4;
      margin-right: $size__gutter / 2;
      background: $color__background_image_placeholder;
    }
    .meta {
      padding: $font__size_base * .125 0;
      line-height: $font__size_base * 1.25;
    }
  }
}

@include media(only-mobile) {

  .section-locals-description br {
    display: none;
  }

}

@include media(desktop) {

  .search-content {
    padding: $size__margin * 4 0;
    background-image: url(images/search_bg@2x.jpg);
  }

  .section-locals {
    &-header {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-heading {
      flex: 0 0 auto;
      width: 55%;
      height: auto;
      padding: 0 $size__gutter * 2 $size__margin * 2;
      box-sizing: border-box;
      @include csshack(ie) {
        width: 55%;
        margin: 0;
      }
      >svg {
        width: 100%;
        height: auto;
      }
    }
    &-description {
      display: block;
      flex: 0 0 auto;
      width: 45%;
      margin-top: 0;
      text-shadow: 0 0 4px rgba(#000, .4);
    }
  }

  .search-body {
    .layout {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    .box {
      flex: 0 0 auto;
      width: calc(50% - #{$size__gutter});
      margin-right: 0;
      margin-left: 0;
      box-sizing: border-box;
      &:only-child {
        width: 100%;
      }
      @include csshack(ie) {
        width: calc(50vw - #{$size__gutter * 2.5});
        min-width: 448px;
        max-width: 508px;
      }
      @at-root .search-result & {
        @include csshack(ie) {
          width: 100%;
          min-width: 0;
          max-width: none;
        }
      }
    }
  }

  .search-locals-gallery {
    margin: -$size__gutter / 2 0 0;
  }

  .search-section {
    .search-query {
      .title>.label,
      .value-list {
        justify-content: center;
      }
      .keyword>.value {
        text-align: center;
        .input-text {
          @include csshack(ie) {
            max-width: 368px;
          }
        }
      }
    }
  }

  .search-result {
    .layout {
      justify-content: center;
    }
    .search-heading {
      @include font-set(xl);
    }
    .search-body {
      .box {
        padding: $size__margin * 2 $size__margin_side * 3;
      }
    }
    .search-query {
      display: table;
      width: 100%;
      border-collapse: collapse;
      &-item {
        display: table-row;
      }
      .title,
      .value {
        display: table-cell;
        padding: $size__margin 0 0;
        vertical-align: middle;
      }
      .title {
        white-space: nowrap;
      }
      .value {
        width: 100%;
        padding-left: $font__size_base;
        box-sizing: border-box;
      }
      .icon {
        display: inline-block;
        max-width: none;
        vertical-align: top;
      }
      &-values {
        .keyword {
          >.value:not(.empty) {
            @include font-set(ms);
          }
        }
      }
    }
    &-list {
      columns: 3;
      column-gap: $size__gutter;
      margin: #{-$size__margin / 2} 0 $size__margin * 2;
      >li {
        margin-top: 0;
        padding-top: $size__margin / 2;
        page-break-inside: avoid;
        break-inside: avoid;
      }
      .image {
        width: $size__base * 5;
        height: $size__base * 5;
      }
    }
  }

}
