html, body,
figure,
h1, h2, h3, h4, h5, h6,
p, ul, ol, li, dl, dt, dd,
form, fieldset, legend, input, button, select, textarea {
  margin: 0;
  padding: 0;
}

header, footer, main,
article, section, nav, aside {
  display: block;
}

html {
  height: 100%;
  background-color: transparent;
  background-image: none;
  font-size: 100%;
}

ul, ol {
  list-style: none;
}

hr {
  color: inherit;
  height: auto;
}

pre {
  font-family: monospace, sans-serif;
}

h1, h2, h3, h4, h5, h6,
strong, b {
  font-weight: bold;
}

em, i {
  font-style: italic;
}

img {
  border: 0;
  vertical-align: bottom;
  -ms-interpolation-mode: bicubic;
}

input, button, select, option, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

table {
  border-collapse: collapse;
  th, td {
    padding: 0;
    border: 0;
    text-align: left;
    vertical-align: baseline;
    @at-root {
      _:-ms-input-placeholder,
      :root & {
        vertical-align: top;
      }
    }
  }
}
