.header,
.content,
.footer {
  overflow: hidden;
}

.inner {
  max-width: $width__max_inner;
  margin: 0 auto;
  padding: 0 $size__margin_side;
  box-sizing: border-box;
}

.inner-ext {
  max-width: $width__max_inner_ext;
}

@include media(desktop) {
  body {
    position: relative;
  }
}
