.archive-content {
  position: relative;
  z-index: 1;
  padding: $size__margin * 2 0;
  background: $color__main_light;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: url(images/archive_bg.svg) center #{-$size__margin * 2} / #{$size__base * 26} auto no-repeat;
    opacity: .3;
  }
}

.archive-table {
  display: block;
  tbody,
  tr,
  th,
  td {
    display: block;
  }
  th {
    margin-top: $size__margin * 1.5;
    font-weight: normal;
  }
  td {
    margin-top: $size__margin * .5;
  }
  a[target='_blank']::after {
    content: '';
    display: inline-block;
    width: $font__size_base;
    height: $font__size_base;
    margin-left: $font__size_base / 4;
    background: url(images/icons/material_icons/open-in-new.svg) center / 100% auto no-repeat;
    vertical-align: middle;
  }
}

.pagination {
  margin: $size__margin * 2 0;
  ul {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    margin: -$size__margin / 2 0 0 #{-$size__gutter / 2};
    >li {
      margin: $size__margin / 2 0 0 $size__gutter / 2;
    }
  }
  .button {
    min-width: $size__base * 2;
    padding: calc(#{$size__margin / 4} - 1px) 0;
    border: 1px solid $color__button;
    box-sizing: border-box;
    text-align: center;
    transition-property: all;
    @include link-selected {
      border-color: $color__button-hover;
    }
    &::after {
      content: none;
      display: none;
    }
  }
  :not(.current) {
    >.button {
      @include button-color-reversed;
    }
  }
}

@include media(tablet) {

  .archive-table {
    display: table;
    width: 100%;
    margin: $size__margin * 3 auto;
    tbody {
      display: table-row-group;
    }
    tr {
      display: table-row;
      &:first-child {
        th,
        td {
          padding-top: 0;
        }
      }
    }
    %table-cell {
      display: table-cell;
      margin: 0;
      padding-top: $size__margin;
    }
    th {
      @extend %table-cell;
      white-space: nowrap;
    }
    td {
      @extend %table-cell;
      width: 100%;
      padding-left: $size__margin_side * 3;
    }
  }

}


@include media(desktop) {

  .archive-content {
    padding: $size__margin * 3 0;
    &::before {
      right: 0;
      max-width: $size__base * 80;
      margin: 0 auto;
      background-position: 0 #{-$size__margin * 2};
      opacity: .3;
    }
  }

}
