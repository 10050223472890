$width__mobile: 568px;
$width__tablet: 768px;
$width__desktop: 960px;

$width__max_inner: 900px;
$width__max_inner_ext: 1080px;
$width__max_body-text: 640px;
$width__max_meta-image: 368px;

$ratio__logo: 448 / 556;
$ratio__logo-locals: 458 / 160;
$ratio__logo-locals-header: 458 / 128;

$size__base: 16px;

$size__margin: 1rem;
$size__margin_side: $size__base;
$size__gutter: $size__base;
