$color__text: #222;
$color__background: #fff;

$color__main_light: #d7e4f7;
$color__main_medium: #bfd2f0;
$color__main_medium-dull: #9ab0d2;
$color__main_deep: #07509b;

$color__header_text: #fff;
$color__header_background: $color__main_deep;
$color__header_logo-yurihama-cho: #fff;

$color__text_search-header: #fff;
$color__text_input-label: #592a74;
$color__text_disabled: #999;

$color__background_image-hover: #fff;
$color__background_image_placeholder: #ccc;

$color__code_border: #ccc;
$color__code_background: #eee;

$color__link: $color__main_deep;
$color__link-hover: darken($color__link, 20%);

$color__button: #8f82bc;
$color__button-hover: darken($color__button, 20%);
$color__button_text: #fff;
