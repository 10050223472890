@charset "UTF-8";
/*!
Theme Name: ゆうゆうゆりはま
Author: Clop
Version: 1.0.0
*/
html, body, figure, h1, h2, h3, h4, h5, h6, p, ul, ol, li, dl, dt, dd, form, fieldset, legend, input, button, select, textarea { margin: 0; padding: 0; }

header, footer, main, article, section, nav, aside { display: block; }

html { height: 100%; background-color: transparent; background-image: none; font-size: 100%; }

ul, ol { list-style: none; }

hr { color: inherit; height: auto; }

pre { font-family: monospace, sans-serif; }

h1, h2, h3, h4, h5, h6, strong, b { font-weight: bold; }

em, i { font-style: italic; }

img { border: 0; vertical-align: bottom; -ms-interpolation-mode: bicubic; }

input, button, select, option, optgroup, textarea { font-family: inherit; font-size: inherit; line-height: inherit; }

table { border-collapse: collapse; }

table th, table td { padding: 0; border: 0; text-align: left; vertical-align: baseline; }

_:-ms-input-placeholder, :root table th, :root table td { vertical-align: top; }

@font-face { font-family: '_YuGo'; src: local("Yu Gothic Medium"); }

@font-face { font-family: '_YuGo'; src: local("Yu Gothic Light"); font-weight: 100; }

@font-face { font-family: '_YuGo'; src: local("Yu Gothic Regular"), local("YuGothic-Regular"); font-weight: 200; }

@font-face { font-family: '_YuGo'; src: local("Yu Gothic Bold"); font-weight: 700; }

@font-face { font-family: '_YuMin'; src: local("Yu Mincho Regular"); }

@font-face { font-family: '_YuMin'; src: local("Yu Mincho Light"), local("YuMincho-Light"); font-weight: 200; }

@font-face { font-family: '_YuMin'; src: local("Yu Mincho Demibold"), local("YuMincho-Demibold"); font-weight: 700; }

@font-face { font-family: '_HiraMin'; src: local("HiraMinProN-W3"), local("HiraMinPro-W3"); }

@font-face { font-family: '_HiraMin'; src: local("HiraMinProN-W6"), local("HiraMinPro-W6"); font-weight: bold; }

@font-face { font-family: '_HiraKaku'; src: local("HiraKakuProN-W3"), local("HiraKakuPro-W3"); }

@font-face { font-family: '_HiraKaku'; src: local("HiraKakuProN-W6"), local("HiraKakuPro-W6"); font-weight: bold; }

body { color: #222; background: #fff; font: 1rem/1.5rem "Noto Sans JP", "_YuGo", "YuGothic", "Yu Gothic", "_HiraKaku", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro W3", "Meiryo", "メイリオ", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; }

.serif { font-family: "Noto Serif JP", "_YuMin", "YuMincho", "Yu Mincho", "_HiraMin", "Hiragino Mincho ProN", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", "Hiragino Mincho Pro", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro W3", "ＭＳ Ｐ明朝", "MS PMincho", serif; }

.body-text { line-height: 2rem; }

.body-text > p:not(:first-child) { margin-top: 1rem; }

.article .body-text { text-align: justify; }

.article .body-text > :not(:first-child) { margin-top: 1rem; }

.article .body-text h2 { font-size: 1.5rem; line-height: 2rem; }

.article .body-text h3, .article .body-text h4 { padding: 0.25rem 0; }

.article .body-text h3 { font-size: 1.25rem; line-height: 1.5rem; }

.article .body-text h4 { font-size: 1.125rem; line-height: 1.5rem; }

.article .body-text h5, .article .body-text h6 { font-size: inherit; line-height: inherit; }

.article .body-text h6 { font-weight: normal; }

.article .body-text ul, .article .body-text ol, .article .body-text dd { margin-left: 2em; }

.article .body-text ul { list-style: disc; }

.article .body-text ol { list-style: decimal; }

.article .body-text li { margin-top: 0.5rem; line-height: 1.5rem; }

.article .body-text > ul li:last-child, .article .body-text > ol li:last-child { padding-bottom: 0.25rem; }

.article .body-text blockquote { position: relative; z-index: 1; margin-right: 16px; margin-left: 48px; }

.article .body-text blockquote::before { content: '“'; position: absolute; z-index: -1; top: -1rem; left: -32px; font-weight: bold; font-size: 10rem; line-height: 1; opacity: .05; }

.article .body-text blockquote p > cite { display: flex; justify-content: flex-end; margin-right: -16px; }

.article .body-text pre, .article .body-text code, .article .body-text kbd { font-family: "Consolas", "Courier New", monospace; }

.article .body-text pre { overflow-x: auto; overflow-y: hidden; }

.article .body-text code, .article .body-text kbd { border: 1px solid #ccc; background: #eee; }

.article .body-text img { max-width: 100%; height: auto; }

.article .body-text figcaption { margin: 0.5rem 0 1rem; font-size: 0.8125rem; line-height: 1.25rem; text-align: left; }

.search-result .search-heading { font-size: 1.5rem; line-height: 2rem; }

@media screen and (min-width: 960px) { .article .body-text > :not(:first-child) { margin-top: 2rem; } }

a { color: #07509b; }

a:hover, a:active, a:focus { color: #031e39; }

img, svg { max-width: 100%; vertical-align: top; }

.image img, .image svg { width: 100%; height: auto; }

.logo-image img, .logo-image svg { width: auto; height: 100%; }

.logo { height: 320px; }

_:-ms-input-placeholder, :root .logo { width: 257.84173px; }

.logo-icon { width: 32px; height: 32px; }

.logo-yurihama-cho { height: 48px; }

.logo-image a > img { transition: background-color 0.2s; }

.logo-image a > img:hover, .logo-image a > img:active, .logo-image a > img:focus { background-color: rgba(255, 255, 255, 0.9); }

.buttons { margin-top: 1rem; text-align: center; }

.button { display: inline-block; position: relative; margin: 0; padding: 0.75rem 16px; border: 0; border-radius: 8px; color: #fff; background: #8f82bc; font-size: 1rem; line-height: 1.5rem; font-feature-settings: 'halt'; text-decoration: none; transition: background-color 0.2s; cursor: pointer; }

.button.arrow { padding-right: 32px; }

.button.arrow .icon { position: absolute; top: 0; right: 16px; bottom: 0; margin: auto 0; width: 12px; height: 12px; background: url(images/icons/arrow.svg) 0 0/100% auto no-repeat; transform: rotate(45deg); }

.button.arrow.back { padding-right: 16px; padding-left: 32px; border-color: currentColor; color: #8f82bc; background: #fff; }

.button.arrow.back:hover, .button.arrow.back:active, .button.arrow.back:focus { border-color: #5a4b8d; color: #fff; background: #5a4b8d; }

.button.arrow.back .icon { right: auto; left: 16px; transform: rotate(-135deg); }

.button:hover, .button:active, .button:focus { color: #fff; background: #5a4b8d; }

.header, .content, .footer { overflow: hidden; }

.inner { max-width: 900px; margin: 0 auto; padding: 0 16px; box-sizing: border-box; }

.inner-ext { max-width: 1080px; }

@media screen and (min-width: 960px) { body { position: relative; } }

.body-text .aligncenter { clear: both; margin-right: auto; margin-left: auto; }

.body-text .wp-caption-text { margin: 0.5rem 0; font-size: 0.875rem; line-height: 1rem; text-align: left; }

.body-text .wp-block-embed-youtube { position: relative; height: 0; padding-top: 56.25%; }

.body-text .wp-block-embed-youtube .wp-block-embed__wrapper { position: absolute; top: 0; right: 0; bottom: 0; left: 0; margin: auto; }

.body-text .wp-block-embed-youtube .wp-block-embed__wrapper iframe { display: block; width: 100%; height: 100%; }

@media screen and (min-width: 568px) { .body-text:after { content: ''; display: block; clear: both; }
  .body-text .alignleft { float: left; margin-right: 16px; }
  .body-text .alignright { float: right; margin-left: 16px; }
  .body-text .clear { clear: both; } }

.header { position: relative; z-index: 1; padding: 0.5rem 0; overflow: hidden; color: #fff; background: #07509b center/cover no-repeat; text-align: center; }

.header .heading { margin: 0 auto; }

.header a { color: inherit; text-decoration: none; transition: text-shadow 0.2s; }

.header a:hover, .header a:active, .header a:focus { text-decoration: underline; text-shadow: 0 0 2px currentColor; }

.header .logo a { transition-property: filter; }

.header .logo a:hover, .header .logo a:active, .header .logo a:focus { filter: drop-shadow(0 0 2px currentColor); }

.header .site-name { font-weight: normal; }

@media screen and (min-width: 960px) { .header .heading { height: 96px; }
  _:-ms-input-placeholder, :root .header .heading { width: 77.35252px; }
  .header .logo-icon { width: 48px; height: 48px; } }

.index-header { background-image: url(images/header_bg.jpg); padding: 4rem 0 2rem; }

.index-header::before, .index-header::after { content: ''; position: absolute; top: 0; bottom: 0; z-index: -1; width: 50%; margin: auto 0; background: url(images/header_bg_rectangles.svg) 0 center/auto 100% repeat-x; }

.index-header::before { right: 50%; margin-right: 160px; background-position: 100% center; }

.index-header::after { left: 50%; margin-left: 160px; }

.index-header .tagline { margin-top: 2rem; font-size: 1.5rem; line-height: 2rem; letter-spacing: .25em; font-feature-settings: 'halt'; }

.index-header .tagline > .text { display: block; margin-right: -.25em; }

.index-header .logo-yurihama-cho { position: absolute; top: 16px; left: 16px; }

.index-header .logo-yurihama-cho img { height: 32px; margin: 0; padding: 8px; border-radius: 4px; background: rgba(255, 255, 255, 0.7); }

@media screen and (min-width: 960px) { .index-header { background-image: url(images/header_bg@2x.jpg); }
  .index-header::before { margin-right: 192px; }
  .index-header::after { margin-left: 192px; }
  .index-header .heading { height: 432px; }
  _:-ms-input-placeholder, :root .index-header .heading { width: 348.08633px; }
  .index-header .tagline { font-size: 2rem; line-height: 3rem; }
  .index-header .logo-yurihama-cho img { height: 48px; } }

.header-locals { background: #fff; }

.header-locals-content { padding: 32px 0; }

.header-locals .logo-locals { height: 64px; color: #07509b; text-align: center; }

_:-ms-input-placeholder, :root .header-locals .logo-locals { width: 229px; }

.header-locals .logo-yurihama-cho { display: none; }

@media screen and (min-width: 960px) { .header-locals { position: absolute; top: 0; right: 0; left: 0; z-index: 5; background: rgba(255, 255, 255, 0.7); }
  .header-locals-content { padding: 16px 0; }
  .header-locals-content { display: flex; justify-content: space-between; align-items: center; }
  .header-locals .logo-yurihama-cho { display: block; height: 40px; } }

.section { margin-top: 2rem; }

.section-header { margin: 0 0 2rem; }

.section-heading { display: flex; flex-flow: column; align-items: center; color: #07509b; font-weight: normal; font-size: 1.5rem; line-height: 2rem; }

.section-heading .text:first-child { padding: 0 32px calc(0.25rem - 1px); border-bottom: 1px solid; }

.section-heading .text.en { margin-top: 0.5rem; font-size: 0.875rem; line-height: 1rem; text-transform: uppercase; }

.article-header { margin: 0 -16px; }

.article-heading > span, .article-header-meta > span { display: block; }

.article { margin: 1rem 0; }

.article-heading { padding: 0 16px calc(1rem - 1px); border-bottom: 1px solid #07509b; color: #07509b; font-weight: normal; font-size: 1.25rem; line-height: 1.5rem; font-feature-settings: 'halt'; }

.article-header-meta { margin: 0.5rem 0; padding: 0 8px; }

.article-header-meta > span { margin: 0 8px; }

.article-content { margin-top: 1rem; }

.article-content .body-text { max-width: 640px; margin: 0 auto; }

@media screen and (min-width: 960px) { .article { margin: 2rem 0; }
  .article-header-meta { display: flex; justify-content: center; }
  .article-heading { font-size: 1.5rem; line-height: 2rem; text-align: center; }
  .article-content { margin-top: 2rem; } }

.archive-content { position: relative; z-index: 1; padding: 2rem 0; background: #d7e4f7; }

.archive-content::before { content: ''; position: absolute; top: 0; left: 0; z-index: -1; width: 100%; height: 100%; background: url(images/archive_bg.svg) center -2rem / 416px auto no-repeat; opacity: .3; }

.archive-table { display: block; }

.archive-table tbody, .archive-table tr, .archive-table th, .archive-table td { display: block; }

.archive-table th { margin-top: 1.5rem; font-weight: normal; }

.archive-table td { margin-top: 0.5rem; }

.archive-table a[target='_blank']::after { content: ''; display: inline-block; width: 1rem; height: 1rem; margin-left: 0.25rem; background: url(images/icons/material_icons/open-in-new.svg) center/100% auto no-repeat; vertical-align: middle; }

.pagination { margin: 2rem 0; }

.pagination ul { display: flex; flex-flow: wrap; justify-content: center; margin: -0.5rem 0 0 -8px; }

.pagination ul > li { margin: 0.5rem 0 0 8px; }

.pagination .button { min-width: 32px; padding: calc(0.25rem - 1px) 0; border: 1px solid #8f82bc; box-sizing: border-box; text-align: center; transition-property: all; }

.pagination .button:hover, .pagination .button:active, .pagination .button:focus { border-color: #5a4b8d; }

.pagination .button::after { content: none; display: none; }

.pagination :not(.current) > .button { border-color: currentColor; color: #8f82bc; background: #fff; }

.pagination :not(.current) > .button:hover, .pagination :not(.current) > .button:active, .pagination :not(.current) > .button:focus { border-color: #5a4b8d; color: #fff; background: #5a4b8d; }

@media screen and (min-width: 568px) { .archive-table { display: table; width: 100%; margin: 3rem auto; }
  .archive-table tbody { display: table-row-group; }
  .archive-table tr { display: table-row; }
  .archive-table tr:first-child th, .archive-table tr:first-child td { padding-top: 0; }
  .archive-table th, .archive-table td { display: table-cell; margin: 0; padding-top: 1rem; }
  .archive-table th { white-space: nowrap; }
  .archive-table td { width: 100%; padding-left: 48px; } }

@media screen and (min-width: 960px) { .archive-content { padding: 3rem 0; }
  .archive-content::before { right: 0; max-width: 1280px; margin: 0 auto; background-position: 0 -2rem; opacity: .3; } }

.home .banners { padding: 2rem 0 0; }

.home .banner-list { margin: -1rem 0 0; }

.home .banner { max-width: 335px; margin: 1rem auto 0; }

.home .banner-link { display: block; overflow: hidden; height: 0; padding-top: 25%; background: center / cover no-repeat; text-indent: 100%; white-space: nowrap; transition: opacity .2s; }

.home .banner-link:hover, .home .banner-link:active, .home .banner-link:focus { opacity: .7; }

.home .banner-link--movie { background-image: url(images/banner_movie@mobile.jpg); }

.home .banner-link--ryourinomachi { background-image: url(images/banner_ryouri@mobile.jpg); }

.home .banner-link--ryourinomachi-2024 { background-image: url(images/banner_ryourinomachi-2024@mobile.jpg); }

.home .banner-link--workation { background-image: url(images/banner_workation@mobile.jpg); }

.home .banner-link--workation-movie { background-image: url(images/banner_workation_movie@mobile.jpg); }

#intro { padding: 2rem 0; }

#intro .inner { display: flex; justify-content: center; }

#intro .body-text > p:not(:first-child) { margin-top: 2rem; }

#intro .body-text > p:last-child { margin-top: 3rem; }

#intro .body-text em { font-style: normal; font-weight: bold; }

#intro .body-text em > em { display: block; font-size: 1.25rem; }

@media screen and (min-width: 960px) { .home { /*     .banner-link { */ /*       &--movie { */ /*         padding-top: 18.1%; */ /*         background-image: url(images/banner_movie@desktop.jpg); */ /*       } */ /*       &--workation { */ /*         padding-top: 18.1%; */ /*         background-image: url(images/banner_workation@desktop.jpg); */ /*       } */ /*     } */ }
  .home .banner-list { display: flex; flex-flow: wrap; justify-content: center; margin: -1rem 0 0 -16px; }
  .home .banner-list .banner { flex: 0 0 auto; width: calc(100% / 3); max-width: 516px; margin: 1rem 0 0 0; padding: 0 0 0 16px; box-sizing: border-box; }
  .home .banner-list--columns { display: block; columns: 3; gap: 16px; margin-left: 0; }
  .home .banner-list--columns .banner { width: auto; margin: 0; padding: 1rem 0 0; }
  #intro .body-text { line-height: 2.5rem; writing-mode: vertical-rl; }
  #intro .body-text > p:not(:first-child) { margin: 0; margin-block-start: 2.5rem; }
  _:-ms-lang(x), #intro .body-text > p:not(:first-child) { margin: 0 2.5rem 0 0; } }

.locals-header { position: relative; background: #d7e4f7 linear-gradient(-45deg, transparent 25%, #bfd2f0 25%, #bfd2f0 50%, transparent 50%, transparent 75%, #bfd2f0 75%) center/12px 12px; }

.locals-image { margin: 0 -16px; }

.locals-footer { margin-top: 1rem; padding: 0 0 1rem; background: #d7e4f7; }

.locals-meta-image { margin: 0 -16px; }

.locals-meta-body { margin-top: 1rem; }

.locals-meta-table { display: block; margin: 1rem -8px 0; font-size: 0.875rem; line-height: 1rem; }

.locals-meta-table tbody, .locals-meta-table tr, .locals-meta-table th, .locals-meta-table td { display: block; padding: 0; }

.locals-meta-table tr { margin-top: 0.5rem; padding: 0 8px; padding-bottom: calc(0.5rem - 1px); border-bottom: 1px solid #9ab0d2; }

.locals-meta-table tr:first-child { padding-top: 0.5rem; border-top: 1px solid #9ab0d2; }

.locals-meta-table th { text-align: left; vertical-align: baseline; }

.locals-meta-table td { margin-top: 0.5rem; padding: 0; }

.locals-meta-table .address > td { margin-top: 0; }

.locals-meta-table .address span { display: inline-block; margin-top: 0.5rem; }

.locals-meta-table .sns ul { display: flex; justify-content: flex-start; margin-left: -16px; }

.locals-meta-table .sns ul li { flex: 0 0 auto; margin-left: 16px; }

.locals-meta-table .sns ul li > a { display: block; position: relative; z-index: 1; width: 32px; height: 32px; background: center / 100% auto no-repeat; transition: opacity 0.2s; overflow: hidden; white-space: nowrap; text-indent: 100%; }

.locals-meta-table .sns ul li > a:hover, .locals-meta-table .sns ul li > a:active, .locals-meta-table .sns ul li > a:focus { opacity: .7; }

.locals-meta-table .sns ul .twitter > a { background-image: url(images/icons/twitter.svg); }

.locals-meta-table .sns ul .facebook > a { background-image: url(images/icons/facebook.svg); }

.locals-meta-table .sns ul .instagram > a { background-image: url(images/icons/instagram.svg); }

.locals-meta-table .body-text { margin: -0.25rem 0; line-height: 1.5rem; text-align: justify; }

.locals-meta-table .body-text > :not(:first-child) { margin-top: 0.5rem; }

.locals-meta-body .button { background: #07509b; }

.locals-meta-body .button:hover, .locals-meta-body .button:active, .locals-meta-body .button:focus { background: #05376a; }

@media screen and (min-width: 960px) { .locals-footer { padding: 2rem 0; }
  .locals-meta { display: flex; justify-content: justify; }
  .locals-meta-image { flex: 0 0 auto; max-width: 368px; margin: 0; }
  .locals-meta-body { flex: 1 1 auto; margin: 0 0 0 24px; }
  .locals-meta-table { display: table; width: 100%; }
  .locals-meta-table tbody { display: table-row-group; }
  .locals-meta-table tr { display: table-row; margin: 0; padding: 0; }
  .locals-meta-table th, .locals-meta-table td { display: table-cell; margin: 0; padding: 1rem 8px calc(1rem - 1px); }
  .locals-meta-table th { white-space: nowrap; }
  .locals-meta-table .address > td { padding-top: 0; }
  .locals-meta-table .address span { margin-top: 1rem; }
  .locals-meta-table .sns > th { vertical-align: middle; } }

.search-content { padding: 2rem 0; background: #07509b center/cover no-repeat fixed; }

.section-locals-header { color: #fff; }

.section-locals-heading { height: 96px; text-align: center; filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.2)); }

_:-ms-input-placeholder, :root .section-locals-heading { width: 274.8px; margin: 0 auto; }

.section-locals-description { display: flex; justify-content: center; margin-top: 1rem; text-shadow: 0 0 4px rgba(0, 0, 0, 0.8); }

.section-locals-description .body-text h2, .section-locals-description .body-text h3 { font-weight: inherit; font-size: inherit; }

.search-body .box { margin: 1rem -8px; padding: 1.5rem 24px 1rem; border-radius: 16px; overflow: hidden; background: rgba(255, 255, 255, 0.85); }

.search-query-item:not(:first-child), .search-query .value { margin-top: 1rem; }

.search-query .title > .label { display: flex; justify-content: flex-start; color: #592a74; font-size: 1.125rem; line-height: 1.5rem; }

.search-query .title > .label > .icon { display: block; flex: 0 0 auto; width: 1.5rem; height: 1.5rem; margin-right: 8px; }

.search-query .value-list { display: flex; flex-flow: wrap; justify-content: flex-start; max-width: 100%; margin-left: -1rem; }

.search-query .value-list > li { flex: 0 0 auto; max-width: 100%; margin-left: 1rem; }

.search-query .value-list .label { display: flex; align-items: baseline; }

.search-query .value-list .label > input { margin-right: 0.5rem; }

.search-query .input-text { width: 100%; padding: 0 calc(4px - 1px); height: 2rem; box-sizing: border-box; }

.search-locals-gallery { display: flex; flex-flow: wrap; justify-content: space-between; margin: calc(-1rem - 8px) -16px 0; }

.search-locals-gallery .image { flex: 0 0 auto; width: calc((100% - 16px) / 3); margin-top: 8px; }

.search-locals-gallery a { transition: opacity 0.2s; }

.search-locals-gallery a:hover, .search-locals-gallery a:active, .search-locals-gallery a:focus { opacity: 0.8; }

.search-result .search-heading { color: #592a74; text-align: center; }

.search-result .search-query-item { margin-top: 1rem; }

.search-result .search-query .value { margin-top: 0.5rem; }

.search-result .search-query .value.empty { color: #999; }

.search-result .search-query .keyword dd:not(.empty)::before, .search-result .search-query .keyword dd:not(.empty)::after { color: #592a74; font-weight: bold; }

.search-result .search-query .keyword dd:not(.empty)::before { content: '“'; margin-right: .25em; }

.search-result .search-query .keyword dd:not(.empty)::after { content: '”'; margin-left: .25em; }

_:-ms-input-placeholder, :root .search-result .search-query .value-list { display: block; }

_:-ms-input-placeholder > li, :root .search-result .search-query .value-list > li { display: inline-block; vertical-align: middle; }

.search-result .result { margin: 2rem 0; }

.search-result .result > em { font-style: normal; font-weight: bold; }

.search-result-list { margin: 2rem -16px; }

.search-result-list li { margin-top: 0.5rem; }

.search-result-list a { display: flex; align-items: flex-start; color: inherit; text-decoration: none; }

.search-result-list a:hover, .search-result-list a:active, .search-result-list a:focus { text-decoration: underline; }

.search-result-list a:hover .image, .search-result-list a:active .image, .search-result-list a:focus .image { background: #fff; }

.search-result-list a:hover .image > img, .search-result-list a:active .image > img, .search-result-list a:focus .image > img { opacity: 0.8; transition: opacity 0.2s; }

.search-result-list .image { flex: 0 0 auto; width: 64px; height: 64px; margin-right: 8px; background: #ccc; }

.search-result-list .meta { padding: 0.125rem 0; line-height: 1.25rem; }

@media screen and (max-width: 567px) { .section-locals-description br { display: none; } }

@media screen and (min-width: 960px) { .search-content { padding: 4rem 0; background-image: url(images/search_bg@2x.jpg); }
  .section-locals-header { display: flex; justify-content: center; align-items: center; }
  .section-locals-heading { flex: 0 0 auto; width: 55%; height: auto; padding: 0 32px 2rem; box-sizing: border-box; }
  _:-ms-input-placeholder, :root .section-locals-heading { width: 55%; margin: 0; }
  .section-locals-heading > svg { width: 100%; height: auto; }
  .section-locals-description { display: block; flex: 0 0 auto; width: 45%; margin-top: 0; text-shadow: 0 0 4px rgba(0, 0, 0, 0.4); }
  .search-body .layout { display: flex; justify-content: space-between; align-items: flex-start; }
  .search-body .box { flex: 0 0 auto; width: calc(50% - 16px); margin-right: 0; margin-left: 0; box-sizing: border-box; }
  .search-body .box:only-child { width: 100%; }
  _:-ms-input-placeholder, :root .search-body .box { width: calc(50vw - 40px); min-width: 448px; max-width: 508px; }
  _:-ms-input-placeholder, :root .search-result .search-body .box { width: 100%; min-width: 0; max-width: none; }
  .search-locals-gallery { margin: -8px 0 0; }
  .search-section .search-query .title > .label, .search-section .search-query .value-list { justify-content: center; }
  .search-section .search-query .keyword > .value { text-align: center; }
  _:-ms-input-placeholder, :root .search-section .search-query .keyword > .value .input-text { max-width: 368px; }
  .search-result .layout { justify-content: center; }
  .search-result .search-heading { font-size: 2rem; line-height: 3rem; }
  .search-result .search-body .box { padding: 2rem 48px; }
  .search-result .search-query { display: table; width: 100%; border-collapse: collapse; }
  .search-result .search-query-item { display: table-row; }
  .search-result .search-query .title, .search-result .search-query .value { display: table-cell; padding: 1rem 0 0; vertical-align: middle; }
  .search-result .search-query .title { white-space: nowrap; }
  .search-result .search-query .value { width: 100%; padding-left: 1rem; box-sizing: border-box; }
  .search-result .search-query .icon { display: inline-block; max-width: none; vertical-align: top; }
  .search-result .search-query-values .keyword > .value:not(.empty) { font-size: 1.125rem; line-height: 1.5rem; }
  .search-result-list { columns: 3; column-gap: 16px; margin: -0.5rem 0 2rem; }
  .search-result-list > li { margin-top: 0; padding-top: 0.5rem; page-break-inside: avoid; break-inside: avoid; }
  .search-result-list .image { width: 80px; height: 80px; } }

.footer { padding: 2rem 0 1rem; background: #d7e4f7; }

.footer-nav { display: flex; flex-flow: wrap; justify-content: center; margin: 1.5rem -16px 0; font-size: 0.875rem; line-height: 1rem; }

.footer-nav li { margin: 0.5rem 16px 0; }

.footer-nav a { display: inline-block; position: relative; padding-left: 1.5rem; }

.footer-nav a .arrow { position: absolute; top: 0; left: 0; width: 1rem; height: 1rem; color: inherit; }

.footer .logo-image { margin: 0 auto; text-align: center; }

.footer .logo { color: #07509b; }

.footer .logo-yurihama-cho { margin: 2rem auto 0; }

.copyright { margin-top: 2rem; color: #07509b; font-size: 0.875rem; line-height: 1rem; text-align: center; }

@media screen and (min-width: 960px) { .footer { padding: 3rem 0 2rem; } }
