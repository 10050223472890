.footer {
  padding: $size__margin * 2 0 $size__margin;
  background: $color__main_light;
  &-nav {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    margin: $size__margin * 1.5 (-$size__margin_side) 0;
    @include font-set(s);
    li {
      margin: $size__margin * 0.5 $size__margin_side 0;
    }
    a {
      display: inline-block;
      position: relative;
      padding-left: $font__size_base * 1.5;
      .arrow {
        position: absolute;
        top: 0;
        left: 0;
        width: $font__size_base;
        height: $font__size_base;
        color: inherit;
      }
    }
  }
  .logo-image {
    margin: 0 auto;
    text-align: center;
  }
  .logo {
    color: $color__main_deep;
  }
  .logo-yurihama-cho {
    margin: $size__margin * 2 auto 0;
  }
}

.copyright {
  margin-top: $size__margin * 2;
  color: $color__main_deep;
  @include font-set(s);
  text-align: center;
}

@include media(desktop) {

  .footer {
    padding: $size__margin * 3 0 $size__margin * 2;
  }

}
