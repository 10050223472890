.header {
  position: relative;
  z-index: 1;
  padding: $size__margin * .5 0;
  overflow: hidden;
  color: $color__header_text;
  background: $color__header_background center / cover no-repeat;
  text-align: center;
  .heading {
    margin: 0 auto;
  }
  a {
    color: inherit;
    text-decoration: none;
    transition: text-shadow $transition__button;
    @include link-selected {
      text-decoration: underline;
      text-shadow: 0 0 2px currentColor;
    }
  }
  .logo {
    a {
      transition-property: filter;
      @include link-selected {
        filter: drop-shadow(0 0 2px currentColor)
      }
    }
  }
  .site-name {
    font-weight: normal;
  }
}

@include media(desktop) {

  .header {
    .heading {
      height: $size__base * 6;
      @include csshack(ie) {
        width: $size__base * 6 * $ratio__logo;
      }
    }
    .logo-icon {
      width: $size__base * 3;
      height: $size__base * 3;
    }
  }

}

@import "header_front-page";
@import "header_locals";
