.locals-header {
  position: relative;
  background: $color__main_light linear-gradient(-45deg, transparent 25%, $color__main_medium 25%, $color__main_medium 50%, transparent 50%, transparent 75%, $color__main_medium 75%) center / 12px 12px;
}

.locals-image {
  margin: 0 (-$size__margin_side);
}

.locals-footer {
  margin-top: $size__margin;
  padding: 0 0 $size__margin;
  background: $color__main_light;
}

.locals-meta {
  &-image {
    margin: 0 (-$size__margin_side);
  }
  &-body {
    margin-top: $size__margin;
  }
  &-table {
    display: block;
    margin: $size__margin (-$size__margin_side / 2) 0;
    @include font-set(s);
    tbody,
    tr,
    th,
    td {
      display: block;
      padding: 0;
    }
    tr {
      @mixin border($pos) {
        padding-#{$pos}: calc(#{$size__margin / 2} - 1px);
        border-#{$pos}: 1px solid $color__main_medium-dull;
      }
      margin-top: $size__margin / 2;
      padding: 0 $size__margin_side / 2;
      @include border(bottom);
      &:first-child {
        padding-top: $size__margin / 2;
        border-top: 1px solid $color__main_medium-dull;
      }
    }
    th {
      text-align: left;
      vertical-align: baseline;
    }
    td {
      margin-top: $size__margin / 2;
      padding: 0;
    }
    .address {
      > td {
        margin-top: 0;
      }
      span {
        display: inline-block;
        margin-top: $size__margin / 2;
      }
    }
    .sns ul {
      display: flex;
      justify-content: flex-start;
      margin-left: -$size__base;
      li {
        flex: 0 0 auto;
        margin-left: $size__base;
        > a {
          display: block;
          position: relative;
          z-index: 1;
          width: $size__base * 2;
          height: $size__base * 2;
          background: center / 100% auto no-repeat;
          transition: opacity $transition__button;
          @include hide-text;
          @include link-selected {
            opacity: .7;
          }
        }
      }
      $sns: twitter, facebook, instagram;
      @each $s in $sns {
        .#{$s} > a {
          background-image: url(images/icons/#{$s}.svg);
        }
      }
    }
    .body-text {
      margin: -$font__size_base / 4 0;
      line-height: $font__line-height_m;
      text-align: justify;
      >:not(:first-child) {
        margin-top: $size__margin / 2;
      }
    }
  }
  &-body .button {
    background: $color__main_deep;
    @include link-selected {
      background: darken($color__main_deep, 10%);
    }
  }
}

@include media(desktop) {
  .locals-footer {
    padding: $size__margin * 2 0;
  }
  .locals-meta {
    display: flex;
    justify-content: justify;
    &-image {
      flex: 0 0 auto;
      max-width: $width__max_meta-image;
      margin: 0;
    }
    &-body {
      flex: 1 1 auto;
      margin: 0 0 0 $size__gutter * 1.5;
    }
    &-table {
      display: table;
      width: 100%;
      tbody {
        display: table-row-group;
      }
      tr {
        display: table-row;
        margin: 0;
        padding: 0;
      }
      th,
      td {
        display: table-cell;
        margin: 0;
        padding: $size__margin $size__gutter / 2 calc(#{$size__margin} - 1px);
      }
      th {
        white-space: nowrap;
      }
      .address {
        >td {
          padding-top :0;
        }
        span {
          margin-top: $size__margin;
        }
      }
      .sns {
        >th {
          vertical-align: middle;
        }
      }
    }
  }
}
